import classNames from 'classnames';
import moment from 'moment';
import React, { FC } from 'react';
import Modal from 'react-responsive-modal';

import { useModal } from '../../common/hooks';
import { Order, OrderStatus } from '../../types/order';
import { BEMClassName } from '../../utils';

interface Props {
    order: Order;
    status: OrderStatus;
}

const StateDropdownModal: FC<Props> = ({ status, order, ...props }) => {
    const { shouldShowModal, openModal, closeModal } = useModal(false);

    const getExtraStatuses = (status, list) => {
        if (status.code !== 'ON_ITS_WAY') {
            return [];
        }
        return list.filter((status, index, statuses) => {
            const nextItemIndex = index + 1;
            return (
                nextItemIndex < statuses.length &&
                statuses[nextItemIndex].code !== status.code &&
                (status.code === 'ON_ITS_WAY' ||
                    status.code === 'ATTEMPTED_DELIVERY' ||
                    status.code === 'DELIVERY_DELAYED')
            );
        });
    };

    const extraStatuses = getExtraStatuses(status, order.status.full);
    const problemStatuses = extraStatuses.filter(fullStatus => {
        return fullStatus.state === 'warning' || fullStatus.state === 'error';
    });

    return extraStatuses.length > 0 && problemStatuses.length > 0 ? (
        <React.Fragment>
            <div className="jd-order-status__step-notice" onClick={openModal}>
                <span>{extraStatuses.length}</span>
            </div>
            <Modal
                open={shouldShowModal}
                onClose={closeModal}
                classNames={{
                    overlay: 'jd-order-status-modal__overlay',
                    modal: 'jd-order-status-modal',
                    closeButton: 'jd-order-status-modal__close-button',
                }}
                center
            >
                <h3>Updates</h3>
                <div className="jd-order-status-modal__list">
                    {extraStatuses.map((modalStatus, index) => {
                        return (
                            <div
                                key={index}
                                className={classNames(
                                    ...BEMClassName(
                                        'jd-order-status-modal__list-item',
                                        ['done', 'warning', 'error'],
                                        modalStatus.state
                                    )
                                )}
                            >
                                <h3>
                                    {modalStatus.title}
                                    <span>{moment(modalStatus.date).format('D.M.Y')}</span>
                                </h3>
                                <p>{modalStatus.description}</p>
                            </div>
                        );
                    })}
                </div>
            </Modal>
        </React.Fragment>
    ) : (
        <></>
    );
};

export default StateDropdownModal;
