import moment from 'moment'
import React, { FC } from 'react'

import { Order } from '../../types/order'

interface Props {
    order: Order | null;
}

const ProgressHeader: FC<Props> = ({ order, ...props }) => {
    return (
        <div className="jd-order-details">
            <div className="jd-order-details__estimate">
                {order !== null && (
                    <React.Fragment>
                        <p>
                            <strong>{order.message.text}</strong>
                        </p>
                        <p>
                            {order.delivery.trackingURL && (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={order.delivery.trackingURL}
                                >
                                    Track with courier
                                </a>
                            )}
                        </p>
                    </React.Fragment>
                )}
            </div>

            <div className="jd-order-details__no">
                <strong>Order No</strong>
                {order !== null && <small>{order.number}</small>}
            </div>
            <div className="jd-order-details__date">
                {order !== null && order.date !== null && (
                    <span>
                        <strong>Order Date</strong>
                        <small>{moment(order.date).format('Do MMM YYYY')}</small>
                    </span>
                )}
            </div>
            <div className="jd-order-details__delivery">
                <strong>Delivery type</strong>
                {order !== null && order.delivery.type}
            </div>
        </div>
    );
};

export default ProgressHeader;
