import classNames from 'classnames';
import React, { FC } from 'react';

import { IForm } from '../../types/form';
import { Order } from '../../types/order';

interface Props {
    form: IForm;
    order: Order | null;
    findOrder: (e?: any) => void;
    formChange: (name: string, value: string) => void;
}

const Form: FC<Props> = ({ form, order, findOrder, ...props }) => {
    const inputChange = event => {
        const { formChange } = props;
        const { name, value } = event.target;
        formChange(name, value);
    };

    const errorsByType = type => form.errors.filter((error: any) => error.type === type);

    // Check for errors in form
    const orderNumberErrors = errorsByType('orderNumber');
    const emailErrors = errorsByType('email');
    const postcodeErrors = errorsByType('postcode');

    return (
        <form
            className={classNames({
                'jd-order-bar__form': true,
                'jd-order-bar__form--searching': form.fetchingOrder,
                'jd-order-bar__form--done': order !== null,
            })}
            onSubmit={findOrder}
        >
            <div
                className={classNames({
                    'jd-order-bar__form-element': true,
                    'jd-order-bar__form-element--invalid': orderNumberErrors.length > 0,
                })}
            >
                <div className="jd-order-bar__form-field">
                    <input
                        className="jd-order-bar__form-input"
                        placeholder="Enter order number"
                        type="text"
                        name="orderNumber"
                        autoComplete="off"
                        value={form.orderNumber}
                        onChange={inputChange}
                    />
                </div>
                {orderNumberErrors.map((error, index) => (
                    <div className="jd-order-bar__form-validation" key={index}>
                        {error.message}
                    </div>
                ))}
            </div>
            <div
                className={classNames({
                    'jd-order-bar__form-element': true,
                    'jd-order-bar__form-element--invalid':
                        emailErrors.length > 0 || postcodeErrors.length > 0,
                })}
            >
                <div className="jd-order-bar__form-field">
                    <input
                        className="jd-order-bar__form-input"
                        placeholder="Your Email address or Postcode"
                        type="text"
                        name="emailOrPostcode"
                        autoComplete="off"
                        value={form.emailOrPostcode}
                        onChange={inputChange}
                    />
                </div>
                {emailErrors.map((error, index) => (
                    <div className="jd-order-bar__form-validation" key={index}>
                        {error.message}
                    </div>
                ))}
                {postcodeErrors.map((error, index) => (
                    <div className="jd-order-bar__form-validation" key={index}>
                        {error.message}
                    </div>
                ))}
            </div>
            <div className="jd-order-bar__form-element">
                <button className="jd-order-bar__form-submit" type="submit">
                    Find My Order
                    {form.fetchingOrder === true && (
                        <span className="jd-order-bar__form-submit-spinner" />
                    )}
                </button>
            </div>
        </form>
    );
};

export default Form;
