import moment from 'moment';

interface OrderStatus {
    name: OrderStatusName;
    state: 'incomplete' | 'warning' | 'error' | 'complete';
    events: OrderStatusEvent[];
}

const ORDER_PLACED = 'Order Placed';
const ORDER_PROCESSING = 'Order Processing';
const ON_ITS_WAY = 'On Its Way';
const READY_TO_COLLECT = 'Ready To Collect';
const COLLECTED = 'Collected';
const DELIVERED = 'Delivered';

type OrderStatusName =
    | typeof ORDER_PLACED
    | typeof ORDER_PROCESSING
    | typeof ON_ITS_WAY
    | typeof READY_TO_COLLECT
    | typeof COLLECTED
    | typeof DELIVERED;

interface OrderStatusEvent {
    event: string;
    date: string;
    message?: string;
    state?: 'info' | 'warning' | 'error';
}

function getBlankHistory(steps): OrderStatus[] {
    return steps.map(step => {
        return {
            name: step,
            state: 'incomplete',
            events: [],
        };
    });
}

type IncomingStatusHistory = Array<{ statusName: string; dateReached: string }>;

interface MappingOptions {
    isAnatwineOrder?: boolean;
    isDeliverToStore?: boolean;
}

export function mapStatusHistory(
    statusHistory: IncomingStatusHistory,
    options?: MappingOptions
): OrderStatus[] {
    statusHistory.sort((a, b) => {
        return moment(a.dateReached).valueOf() - moment(b.dateReached).valueOf();
    });

    /** The steps of the progress bar that the user will see */
    const steps: OrderStatusName[] = options?.isDeliverToStore
        ? [ORDER_PLACED, ORDER_PROCESSING, ON_ITS_WAY, READY_TO_COLLECT, COLLECTED]
        : [ORDER_PLACED, ORDER_PROCESSING, ON_ITS_WAY, DELIVERED];

    let history: OrderStatus[] = getBlankHistory(steps);

    interface AddToHistoryParams {
        event: OrderStatusEvent;
        target: OrderStatusName | null;
        options?: {
            shouldComplete?: boolean;
        };
    }

    const utils = {
        /** Set every status to complete that is before the `finalIndex` */
        completeStatusesUpto(finalIndex) {
            for (let index in history) {
                if (index < finalIndex) {
                    history[index].state = 'complete';
                }
            }
        },
        addToHistory({ event, target, options }: AddToHistoryParams) {
            const shouldAddToLastStatus = !target;

            const isCompleted = ({ state }) => state === 'complete';
            const isTargetStatus = ({ name }) => name === target;

            let statusIndex: number;
            statusIndex = shouldAddToLastStatus
                ? history.findIndex(status => !isCompleted(status))
                : history.findIndex(isTargetStatus);

            if (statusIndex > 0) {
                utils.completeStatusesUpto(statusIndex);
            }

            if (options?.shouldComplete) {
                history[statusIndex || 0].state = 'complete';
            }

            history[statusIndex || 0].events.push(event);
        },
    };

    for (let { statusName, dateReached } of statusHistory) {
        const eventTemplate = getEventTemplate(statusName, options);
        if (!eventTemplate) continue;

        const { target, message = '', state = 'info', shouldComplete } = eventTemplate;

        const event: OrderStatusEvent = {
            event: statusName,
            date: dateReached!.toString(),
            message,
            state,
        };

        utils.addToHistory({ event, target, options: { shouldComplete } });
    }

    return history;
}

interface EventTemplate {
    /** Which status the event should be applied to */
    target: OrderStatusName | null;
    message?: string;
    state: OrderStatusEvent['state'];
    /** Whether the event should complete the current status, mainly for Delivered status */
    shouldComplete?: boolean;
}

function getEventTemplate(statusCode: string, options?: MappingOptions): EventTemplate | null {
    switch (statusCode.toLowerCase()) {
        case 'paid':
        case 'paid_offline':
        case 'paid offline':
        case 'pending_offline_payment':
        case 'pending offline payment':
            return {
                target: ORDER_PLACED,
                message: 'Payment has been accepted',
                state: 'info',
            };
        case 'accepted':
        case 'awaiting despatch':
            return {
                target: ORDER_PROCESSING,
                state: 'info',
                message: 'Your order is current being processed',
            };
        case 'despatched':
        case 'despatched_to_home':
        case 'despatched_to_store':
        case 'partial_despatch':
            return {
                target: ON_ITS_WAY,
                message: 'Your order has been despatched',
                state: 'info',
                shouldComplete: true,
            };
        case 'out for delivery':
            return {
                target: ON_ITS_WAY,
                message: 'Your order is out for delivery',
                state: 'info',
            };
        case 'arrived_in_store':
            return {
                target: READY_TO_COLLECT,
                message: 'Your order is ready to collect instore',
                state: 'info',
                shouldComplete: true,
            };
        case 'carrier delays':
            return {
                target: ON_ITS_WAY,
                message: 'Your delivery is running late',
                state: 'warning',
            };
        case 'attempted delivery':
            return {
                target: ON_ITS_WAY,
                state: 'warning',
                message: 'The courier attempted a delivery but was unable to deliver your order.',
            };
        case 'customer to collect from carrier':
            return {
                target: ON_ITS_WAY,
                state: 'warning',
                message:
                    'Your order has been returned to the carrier facility and will need to be collected',
            };
        case 'delivered':
        case 'delivered in part':
        case 'delivered to alternate delivery point':
        case 'delivered to locker/collection point':
            return {
                target: DELIVERED,
                state: 'info',
                message: `Your ${options?.isAnatwineOrder ? 'JD ' : ''}order has been delivered`,
                shouldComplete: true,
            };
        case 'delivered - specified safe place':
            return {
                target: DELIVERED,
                state: 'info',
                message: 'Your order has been left in your designated safe place',
                shouldComplete: true,
            };
        case 'delivered to neighbour':
            return {
                target: DELIVERED,
                state: 'info',
                message: 'Your order has been left with a neighbour',
                shouldComplete: true,
            };
        case 'collected':
        case 'collected_by_customer':
        case 'collected_from_store':
            return {
                target: COLLECTED,
                state: 'info',
                message: 'Your order has been collected',
                shouldComplete: true,
            };
        case 'refused by customer':
        case 'parcel lost':
        case 'parcel damaged':
        case 'address query':
        case 'non delivery':
        case 'no access to recipients address':
            return {
                target: null,
                state: 'error',
                message: 'The courier had a problem when trying to deliver your order',
            };
        case 'line_cancelled':
            return {
                target: ORDER_PROCESSING,
                state: 'warning',
                message: 'Some of your items have been cancelled.',
            };
        default:
            return null;
    }
}

const hasCompletedStatus = status => {
    return ({ name, state }) => {
        return name === status && state === 'complete';
    };
};

export function getCombinedStatus(statusHistories: OrderStatus[][]): OrderStatusName {
    const allStatusNames: OrderStatusName[] = [
        DELIVERED,
        COLLECTED,
        READY_TO_COLLECT,
        ON_ITS_WAY,
        ORDER_PROCESSING,
        ORDER_PLACED,
    ];

    return (
        allStatusNames.find(statusName =>
            statusHistories.every(history => history.find(hasCompletedStatus(statusName)))
        ) ?? ORDER_PLACED
    );
}

export function getCurrentStatus(history: OrderStatus[]): OrderStatus {
    const statusesWithEvents = history.filter(status => status.events.length > 0);
    return statusesWithEvents[statusesWithEvents.length - 1];
}

export function getCurrentStatusName(history: OrderStatus[]): OrderStatusName {
    return getCurrentStatus(history).name;
}

export function getCombinedStatusMessage(
    currentStatus: OrderStatusName,
    histories: OrderStatus[][]
) {
    let message: string | undefined = '';

    try {
        const matchingStatuses = histories.flat().filter(status => status.name === currentStatus);

        const [mostRecentEvent] = matchingStatuses
            .map(({ events }) => events[events.length - 1])
            .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());

        message = mostRecentEvent.message;
    } catch (e) {}

    return message;
}
