import { Order } from '../types/order';
import {
    getCombinedStatus,
    getCombinedStatusMessage,
    mapStatusHistory,
} from '../utils/orderStatus';

const apiUrl = 'https://data.smartagent.io/v1/jdsports/track-my-order';

class API {
    getOrder(orderNumber: string, emailOrPostcode: string, facia: string): Promise<Order | Error> {
        return new Promise((resolve, reject) => {
            let url = `${apiUrl}?orderNumber=${orderNumber}&facia=${facia}`;

            if (emailOrPostcode.indexOf('@') !== -1) {
                url += '&emailAddress=' + emailOrPostcode;
            } else {
                url += '&postcode=' + emailOrPostcode;
            }

            fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                if (response.ok) {
                    response
                        .json()
                        .then(hackInChildOrderStatuses)
                        .then(data => resolve(data));
                } else {
                    response.json().then(error => reject(error));
                }
            });
        });
    }
}

const instance = new API();
export default instance;

/**
 * Child order statuses aren't included in the status history from the API,
 * so we need to get the combined status of all the child orders and add it
 * to the "parent" status history
 *
 * Ideally this would not be needed here, and would be done on the API instead,
 * but would have been too much work to amend the existing API within the time
 * constraints
 */
const hackInChildOrderStatuses = (data: Order) => {
    if (!data.childOrders) return data;

    // Convert the status history to a better format
    const childHistories = data.childOrders.map(co => mapStatusHistory(co.statusHistory ?? []));

    // Get's the least complete status of combined child orders
    const childStatus = getCombinedStatus(childHistories);
    const childMessage: string | undefined = getCombinedStatusMessage(childStatus, childHistories);

    // Where is that status in the "parent" status history?
    const indexOfMatchingStatus = data.status.short.findIndex(s => s.title === childStatus);

    // If the status is in the "parent" history, mark it as done and update the message
    if (indexOfMatchingStatus > -1) {
        data.status.short[indexOfMatchingStatus].state = 'done';
        if (childMessage) {
            data.message.text = childMessage;
            data.status.short[indexOfMatchingStatus].description = childMessage;
        }
    }

    return data;
};
