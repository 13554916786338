import classNames from 'classnames';
import moment from 'moment';
import React, { FC } from 'react';

import { Order } from '../../types/order';
import { BEMClassName } from '../../utils';
import StateDropdownModal from './StateDropdownModal';

const progressStates = ['done', 'warning', 'error', 'incomplete'];

interface Props {
    order: Order | null;
}

const ProgressStatus: FC<Props> = ({ order, ...props }) => {
    const activeStatuses =
        order === null ? [] : order.status.short.filter(s => s.state !== 'incomplete');

    return (
        <div className="jd-order-status">
            {order !== null &&
                order.status.short.map((status, index) => {
                    return (
                        <div
                            key={index}
                            className={classNames(
                                ...BEMClassName(
                                    'jd-order-status__step',
                                    progressStates,
                                    status.state
                                )
                            )}
                        >
                            <div className="jd-order-status__step-heading">
                                {status.title}
                                <StateDropdownModal status={status} order={order} />
                            </div>
                            {status.date && <small>{moment(status.date).format('DD.MM.YY')}</small>}
                            {status.description && index + 1 === activeStatuses.length && (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: status.description,
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

export default ProgressStatus;
