import { useDebugValue, useState } from 'react';

export const useBoolean = (defaultValue?: boolean) => {
    const [value, setValue] = useState<boolean>(defaultValue || false);

    const setTrue = () => setValue(true);
    const setFalse = () => setValue(false);
    const toggle = () => setValue(!value);

    return { value, setTrue, setFalse, toggle };
};

export const useModal = (defaultValue?: boolean) => {
    const { value, setTrue, setFalse, toggle } = useBoolean(defaultValue || false);

    useDebugValue(value ? 'Open' : 'Closed');

    return {
        shouldShowModal: value,
        openModal: setTrue,
        closeModal: setFalse,
        toggleModal: toggle,
    };
};
