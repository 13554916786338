export const agent_url = 'https://www.jdsports.co.uk';
export const THEMES = [
    'jdsportsuk',
    'scottsmenswear',
    'tessuti',
    'size',
    'footpatrolgb',
    'blacks',
    'millets',
    'ultimateoutdoors',
];
