import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

/** Broadcast up page size changes. If we are in an iframe, listeners can resize the iframe to fit.
    We query 20 times a second - be aware of possible performance implications and reduce interval frequency if required. */
const broadcastHeightChanges = () => {
    var body = document.getElementsByTagName('body')[0];
    var height = body.scrollHeight;

    setInterval(() => {
        if (body.scrollHeight === height) return;
        height = body.scrollHeight;
        var messageToSend = {
            type: 'heightChange',
            height: height,
        };
        window.parent.postMessage(messageToSend, '*');
    }, 50);
};
broadcastHeightChanges();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
