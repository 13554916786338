export const getQueryParams = (): Record<string, string> => {
    const params = new URLSearchParams(window.location.search)
    return Object.fromEntries(params.entries())
};

export const getFacia = () => {
    const { facia, fascia } = getQueryParams();
    return facia || fascia;
};

export const decimal = num => {
    let d;
    try {
        d = parseFloat(num).toFixed(2);
        return d;
    } catch (e) {
        return null;
    }
};

/**
 * Helper function to append a modifier to a class name
 * @example
 * const modifierList = ['warning','error'];
 * const className = 'myElement';
 * classNameModifier(className, modifierList, 'error'); // ['myElement', 'myElement--error']
 * classNameModifier(className, modifierList, 'success'); // ['myElement']
 */
export const BEMClassName = (className: string, modifierList: string[], modifier: string = '') => {
    let classes = [className];
    if (modifierList.includes(modifier)) {
        classes.push(`${className}--${modifier}`);
    }
    return classes;
};
