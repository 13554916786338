/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC } from 'react'

import { useModal } from '../../common/hooks'
import { Vendor } from '../../types/order'

interface Props {
    title: string;
    vendor: Vendor;
    trackingURL?: string;
}

const OrderDetailsHeader: FC<Props> = ({ title, vendor, trackingURL }) => {
    const { shouldShowModal, toggleModal } = useModal(false);

    return (
        <div className="jd-order-info__items-header">
            {vendor.imageURL && <img src={vendor.imageURL + '?h=40&w=40'} alt={vendor.name} />}
            <div>
                <h4>
                    <span>{title}</span>
                    {trackingURL && (
                        <span className="jd-order-info__tracking-link">
                            <a href={trackingURL} target="_blank" rel="noreferrer noopener">
                                track package
                            </a>
                        </span>
                    )}
                </h4>
                {vendor.deliveryMessage && (
                    <p>
                        {vendor.deliveryMessage}{' '}
                        {vendor.message && <a onClick={toggleModal}>What's this?</a>}
                    </p>
                )}
                {vendor.message && shouldShowModal && (
                    <p
                        onClick={toggleModal}
                        className={`jd-order-info__items-vendor-message ${
                            shouldShowModal ? 'hidden' : 'visible'
                        }`}
                    >
                        {vendor.message}
                    </p>
                )}
            </div>

            {/* {vendor.message && (
                <Modal
                    classNames={{
                        overlay: 'jd-order-status-modal__overlay',
                        modal: 'jd-order-status-modal',
                        closeButton: 'jd-order-status-modal__close-button',
                    }}
                    open={shouldShowModal}
                    onClose={closeModal}
                    center
                >
                    <h3>Fulfilment Information</h3>
                    <p>{vendor.message}</p>
                </Modal>
            )} */}
        </div>
    );
};

export default OrderDetailsHeader;
