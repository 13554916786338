import classNames from 'classnames';
import React, { FC } from 'react';

import { Order } from '../../types/order';
import { BEMClassName } from '../../utils';

interface Props {
    order: Order;
}

const progressStates = ['done', 'warning', 'error', 'incomplete'];

const Progress: FC<Props> = ({ order, ...props }) => {
    return order !== null ? (
        <div className="jd-order-bar__progress">
            {order.status.short.map((status, index) => {
                const statusClasses = classNames(
                    ...BEMClassName('jd-order-status__progress-step', progressStates, status.state)
                );
                return <div key={index} className={statusClasses} />;
            })}
        </div>
    ) : (
        <></>
    );
};

export default Progress;
