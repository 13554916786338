import './App.scss'

import React, { FC, useEffect, useMemo, useState } from 'react'

import API from './api'
import TrackMyOrder from './components/TrackMyOrder'
import { THEMES } from './config'
import { Customer } from "./types/customer"
import { IForm } from './types/form'
import { Order } from "./types/order"
import { getQueryParams, getFacia } from './utils'

const App: FC = props => {
    const params = getQueryParams();

    const [customer, updateCustomer] = useState<Customer | null>(null);
    const [order, updateOrder] = useState<Order | null>(null);

    // Set the initial form state
    const [form, updateForm] = useState<IForm>({
        orderNumber: params.orderNumber || '',
        emailOrPostcode: params.email || params.postcode || '',
        errors: [],
        fetchingOrder: false,
    });

    useEffect(() => {
        window.addEventListener('message', listenForCustomerDataMesssage);
        if (!!form.orderNumber && !!form.emailOrPostcode) {
            findOrder();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateForm(prevForm => ({
            ...prevForm,
            emailOrPostcode: customer ? customer.email : prevForm.emailOrPostcode,
        }));
    }, [customer]); // Update the form when the customer changes

    const listenForCustomerDataMesssage = ({ data }) => {
        if (data && data.customer && data.customer.customerID) {
            updateCustomer(data.customer);
            window.removeEventListener('message', listenForCustomerDataMesssage);
        }
    };

    const formChange = (name: string, value: string) => {
        updateForm(prevForm => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const setErrors = (errors: IForm['errors']) => {
        updateForm(prevForm => ({ ...prevForm, errors }));
    };

    const setFetching = (fetching: boolean) => {
        updateForm(prevForm => ({
            ...prevForm,
            fetchingOrder: fetching,
        }));
    };

    const findOrder = (event?: any) => {
        // Dont submit form
        if (event) event.preventDefault();

        const { orderNumber, emailOrPostcode } = form;

        const errors: IForm['errors'] = [];

        if (!orderNumber) {
            errors.push({
                type: 'orderNumber',
                message: 'please enter your order number',
            });
        }

        if (!emailOrPostcode) {
            errors.push({
                type: 'email',
                message: 'please enter your email address or postcode',
            });
        }

        if (!orderNumber || !emailOrPostcode) {
            setErrors(errors);
            return;
        }

        setFetching(true);

        API.getOrder(orderNumber, emailOrPostcode, getFacia() || 'jdsportsuk')
            .then(order => {
                setFetching(false);
                updateOrder(order as Order);
                setErrors([]);
            })
            .catch(error => {
                setFetching(false);
                setErrors([...errors, error]);
            });
    };

    /* Only compute the theme name on mount */
    const themeName = useMemo(() => {
        const defaultTheme = THEMES[0];
        const facia = getFacia();
        return !!facia && THEMES.includes(facia.toLowerCase()) ? facia : defaultTheme;
    }, []);

    return (
        <div className={`theme-${themeName}`}>
            <TrackMyOrder
                order={order}
                form={form}
                formChange={formChange}
                findOrder={findOrder}
            />
        </div>
    );
};

export default App;
