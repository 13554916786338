import classnames from 'classnames';
import React, { FC } from 'react';

import { IForm } from '../types/form';
import { Order } from '../types/order';
import Header from './Header';
import OrderDetails from './OrderDetails/OrderDetails';
import Tracker from './Tracker/Tracker';

interface Props {
    order: Order | null;
    form: IForm;
    formChange: (name: string, value: string) => void;
    findOrder: (e?: any) => void;
}

const TrackMyOrder: FC<Props> = props => {
    const { order, form, formChange, findOrder } = props;
    return (
        <div className={classnames('jd-order', { 'jd-order--found': order !== null })}>
            <Header order={order} />
            <Tracker order={order} form={form} formChange={formChange} findOrder={findOrder} />
            <OrderDetails order={order}  />
        </div>
    );
};

export default TrackMyOrder;
