/* eslint-disable jsx-a11y/anchor-is-valid */

import moment from 'moment';
import React, { FC } from 'react';
import Modal from 'react-responsive-modal';

import { useModal } from '../../common/hooks';
import { Item } from '../../types/order';

interface Props {
    item: Item;
}

const OrderDetailsRefund: FC<Props> = ({ item, ...props }) => {
    const { shouldShowModal, openModal, closeModal } = useModal(false);

    return (
        <div className="jd-order-info__items-error">
            <a onClick={openModal}>
                <strong>Refunded</strong>
            </a>
            <p>Item has been refunded</p>
            <p>{item.refundDate && moment(item.refundDate).format('Do MMM YYYY')}</p>
            <Modal
                classNames={{
                    overlay: 'jd-order-status-modal__overlay',
                    modal: 'jd-order-status-modal',
                    closeButton: 'jd-order-status-modal__close-button',
                }}
                open={shouldShowModal}
                onClose={closeModal}
                center
            >
                <h3>Refunds</h3>
                <p>
                    It can take between 3-5 working days for your refund to be returned to your
                    account. If you haven’t received your refund after this time, then please
                    contact our Customer Service team who will be happy to look in to
                </p>
            </Modal>
        </div>
    );
};

export default OrderDetailsRefund;
