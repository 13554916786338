import React, { FC } from 'react';

import { IForm } from '../../types/form';
import { Order } from '../../types/order';
import Form from './Form';
import Progress from './Progress';
import ProgressHeader from './ProgressHeader';
import ProgressStatus from './ProgressStatus';

interface Props {
    form: IForm;
    order: Order | null;
    findOrder: (e?: any) => void;
    formChange: (name: string, value: string) => void;
}

const Tracker: FC<Props> = ({ order, form, formChange, findOrder, ...props }) => {
    return (
        <div className="jd-order-tracker">
            <ProgressHeader order={order} />
            <div className="jd-order-bar">
                {!order ? (
                    <Form form={form} order={order} formChange={formChange} findOrder={findOrder} />
                ) : (
                    <Progress order={order} />
                )}
            </div>
            <ProgressStatus order={order} />
        </div>
    );
};

export default Tracker;
