import React, { FC } from 'react'
import { Order } from '../types/order'

interface Props {
    order: Order | null;
}

const Header: FC<Props> = ({ order }) => {
    function getFAQURL() {
        const url = new URL(document.referrer || document.location.href)
        return `${url.origin}/customer-service/faqs`
    }

    return (
        <header className="jd-order-header">
            <div className="jd-order-header__title">
                <h1 className="jd-order-header__text">
                    {order === null ? 'Track my order' : `Order Number: ${order.number}`}
                </h1>
                {order !== null && (
                    <div className="jd-order-header__chat">
                        <a href={getFAQURL()} rel="noreferrer" target='_blank'>
                            Need Help?
                        </a>
                    </div>
                )}
            </div>
            <div className="jd-order-header__subtitle">
                To see the status of your order all you need is your order number and your email
                address or postcode. This is found on your order confirmation or dispatch email
            </div>
        </header>
    );
};

export default Header;
