import classnames from 'classnames'
import getSymbolFromCurrency from 'currency-symbol-map'
import React, { FC } from 'react'

import { MonetaryAmount, Order } from '../../types/order'
import { decimal } from '../../utils'
import { addressToArray } from '../../utils/address'
import getPaymentMethodImage from '../../utils/getPaymentMethodImage'
import OrderDetailsHeader from './OrderDetailsHeader'
import OrderDetailsRefund from './OrderDetailsRefund'

/** Currency symbol with decimal amount */
const Price: FC<MonetaryAmount> = ({ amount, currency }) => (
    <>
        {/* <></> is the same as React.Fragment */}
        {getSymbolFromCurrency(currency)}
        {decimal(amount)}
    </>
);

interface Props {
    order: Order | null;
}

const OrderDetails: FC<Props> = ({ order }) => {
    const imageURLQueryParams = (src: string) => {
        if (src.indexOf('?') === -1) {
            src += '?qlt=60';
        }
        return src + '&w=300&h=300&fmt=jpg&sm=C';
    };

    const childOrderSKUs = order && order.childOrders ? order.childOrders.map(co => co.SKU) : [];
    const isChildOrderSKU = SKU => childOrderSKUs.some(childSKU => SKU.indexOf(childSKU) > -1);

    const renderChildOrders = () => {
        if (!order || !order.childOrders) return;

        return order.childOrders.map((childOrder, index) => {
            const JDVendor = order.vendors[0];
            const items = JDVendor.items.filter(item => item.sku.indexOf(childOrder.SKU) > -1);

            return (
                <div
                    className={classnames({
                        'jd-order-info__items': true,
                    })}
                    key={childOrder.childID}
                >
                    <OrderDetailsHeader
                        title={`Package ${index + 1} (#${childOrder.childID})`}
                        vendor={{ name: 'JD Sports', imageURL: order.vendors[0].imageURL } as any}
                        trackingURL={childOrder.trackingURL}
                    />
                    <ul className="jd-order-info__items-list">
                        {items.map(item => (
                            <li className="jd-order-info__items-item" key={item.id}>
                                <span className="jd-order-info__items-image">
                                    <img src={imageURLQueryParams(item.img)} alt={item.name} />
                                </span>
                                <h5 className="jd-order-info__items-price">
                                    <strong>
                                        <Price {...item.price} />
                                    </strong>
                                </h5>
                                <h5 className="jd-order-info__items-title">{item.name}</h5>
                                <span className="jd-order-info__items-size">Size: {item.size}</span>
                                <span className="jd-order-info__items-qty">Qty: {item.qty}</span>
                                <span className="jd-order-info__items-code">
                                    Code: {item.sku.split('.')[0]}
                                </span>
                                {item.refunded && <OrderDetailsRefund item={item} />}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        });
    };

    const renderOrders = () => {
        return (
            order !== null &&
            order.vendors
                .filter(vendor => vendor.items.some(item => !isChildOrderSKU(item.sku)))
                .map((vendor, index) => {
                    return (
                        <div
                            className={classnames({
                                'jd-order-info__items': true,
                                'jd-order-info__items--singleVendor': order.vendors.length === 1,
                            })}
                            key={index}
                        >
                            <OrderDetailsHeader
                                title={
                                    order.vendors.length === 1
                                        ? 'Order Items'
                                        : `Shipped by ${vendor.name}`
                                }
                                vendor={vendor}
                            />
                            <ul className="jd-order-info__items-list">
                                {vendor.items
                                    .filter(item => !isChildOrderSKU(item.sku))
                                    .map(item => (
                                        <li className="jd-order-info__items-item" key={item.id}>
                                            <span className="jd-order-info__items-image">
                                                <img
                                                    src={imageURLQueryParams(item.img)}
                                                    alt={item.name}
                                                />
                                            </span>
                                            <h5 className="jd-order-info__items-price">
                                                <strong>
                                                    <Price {...item.price} />
                                                </strong>
                                            </h5>
                                            <h5 className="jd-order-info__items-title">
                                                {item.name}
                                            </h5>
                                            <span className="jd-order-info__items-size">
                                                Size: {item.size}
                                            </span>
                                            <span className="jd-order-info__items-qty">
                                                Qty: {item.qty}
                                            </span>
                                            <span className="jd-order-info__items-code">
                                                Code: {item.sku.split('.')[0]}
                                            </span>
                                            {item.refunded && <OrderDetailsRefund item={item} />}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    );
                })
        );
    };

    return (
        <div className="jd-order-info columns">
            <div className="column column--left">
                {renderChildOrders()}
                {renderOrders()}
            </div>
            <div className="column column--right">
                {order !== null ? (
                    <div className="jd-order-info__total">
                        <ul className="jd-order-info__total-list">
                            <li className="jd-order-info__total-list-item">
                                <span>Subtotal before discount</span>
                                <strong>
                                    <Price
                                        currency={order.totals.total.currency}
                                        amount={(
                                            parseFloat(order.totals.basket.amount) +
                                            parseFloat(order.totals.discount.amount)
                                        ).toFixed(2)}
                                    />
                                </strong>
                            </li>
                            <li className="jd-order-info__total-list-item jd-order-info__total-list-item--discount">
                                <span>Discount</span>
                                <strong>
                                    -
                                    {parseFloat(order.totals.discount.amount) > 0 && (
                                        <Price {...order.totals.discount} />
                                    )}
                                </strong>
                            </li>
                            <li className="jd-order-info__total-list-item">
                                <React.Fragment>
                                    <span>Delivery</span>
                                    <strong>
                                        {parseFloat(order.totals.delivery.amount) === 0 ? (
                                            'FREE'
                                        ) : (
                                            <Price {...order.totals.delivery} />
                                        )}
                                    </strong>
                                </React.Fragment>
                            </li>
                            <li className="jd-order-info__total-list-item jd-order-info__total-list-item--total">
                                <span>Total</span>
                                <strong>
                                    <Price {...order.totals.total} />
                                </strong>
                            </li>
                        </ul>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default OrderDetails;
